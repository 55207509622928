<template>
  <v-container>
    <v-layout column>
      <v-flex class="display-2 text-xs-center my-5">Big Title Goes Here</v-flex>
      <v-flex>
        <div class="headline mt-3">Lorem ipsum</div>
        <p class="subtitle-1 mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pulvinar risus quis
          mauris interdum, in euismod nibh pretium. Etiam pulvinar tincidunt dapibus. Quisque sollicitudin, mauris a
          consequat consectetur, turpis nisl sollicitudin enim, id consectetur neque neque nec metus. Pellentesque dolor
          nisi, vulputate quis lobortis ac, tincidunt et quam. Mauris pulvinar blandit nisi nec mattis. Aliquam accumsan
          ut sem eget efficitur. Vivamus in tortor gravida eros laoreet condimentum nec vel dui. Nullam quam massa,
          ultrices eget tincidunt a, pulvinar ac libero.</p>
      </v-flex>
      <v-flex>
        <div class="headline mt-3">Lorem ipsum</div>
        <p class="subtitle-1 mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, aut autem distinctio expedita ipsum quos repellendus. Architecto consequuntur dolorum nulla voluptatem. Accusamus, cum dicta dignissimos doloribus et in odit quam?</p>
        <p class="subtitle-2 mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa dolorum nesciunt nisi officiis quaerat sit suscipit? Aut doloribus dolorum, ea, explicabo fuga fugit, nihil nobis quam sapiente sit temporibus voluptates.</p>
        <p class="subtitle-2 mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. At facilis nostrum provident rerum sed tempora unde. Accusantium debitis, et facere fugiat, libero maiores odit officiis quaerat quasi, qui quod temporibus.</p>
      </v-flex>
      <v-flex>
        <div class="headline mt-3">Lorem ipsum</div>
        <p class="subtitle-1 mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, aut autem distinctio expedita ipsum quos repellendus. Architecto consequuntur dolorum nulla voluptatem. Accusamus, cum dicta dignissimos doloribus et in odit quam?</p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "HomeDetails"
}
</script>

<style scoped>

</style>
